import './CategoryCard.scss';
import { NavLink } from 'react-router-dom';

const CategoryCard = ({ icon, url, title, vehicles }) => {
	return (
		<div className='category-card'>
			<div className='header'>
				<img src={icon} alt='prezentare categorie' />
			</div>
			<div className='body'>
				<p className='title'>{title}</p>
				<div className='vehicles'>
					{vehicles.map((vehicle, index) => (
						<p key={index}>• {vehicle}</p>
					))}
				</div>
			</div>
			<NavLink to={url} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className='button'>
				Vezi
			</NavLink>
		</div>
	);
};
export default CategoryCard;
