import CategoryCard from '../../components/CategoryCard/CategoryCard';
import './OfertaPage.scss';
import { categories } from '../../SchoolData';

const OfertaPage = () => {
	return (
		<div className='page page-oferta'>
			{categories.map((category) => (
				<CategoryCard icon={category.icon} url={category.url} title={category.title} vehicles={category.vehicles} />
			))}
		</div>
	);
};
export default OfertaPage;
