import './HomePage.scss';
import { categories } from '../../SchoolData';
import { NavLink } from 'react-router-dom';
import HeroImage from '../../images/hero.webp';
import Testimonial1 from '../../images/testimonials/3.webp';
import Testimonial2 from '../../images/testimonials/6.webp';
import { LoadingOverlay } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import { schoolImages } from '../../SchoolData';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

const HomePage = () => {
	const [fileLoadingOverlay, setFileLoadingOverlay] = useState(true);
	const swiperRef = useRef();

	useEffect(() => {
		const interval = setInterval(() => {
			swiperRef.current.swiper.slideNext();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className='page page-home'>
			<div className='presentation'>
				<img src={HeroImage} alt='scoala' />
				<p>Auto Star Driver</p>
			</div>
			<div className='categorii'>
				<NavLink to='/oferta' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
					<p className='title'>Categorii</p>
				</NavLink>
				<div className='body'>
					<p className='description'>
						Oferim oferte pentru o varietate de categorii precum B, C si CE. Avand la dispozitie o echipa de
						instructori experimentati si vehicule noi.
					</p>
					<div className='icons'>
						{categories.map((category) => (
							<NavLink to={category.url} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
								<img src={category.icon} alt='categorie' />
							</NavLink>
						))}
					</div>
				</div>
			</div>
			<div className='testimonials'>
				<p>Experienta elevilor nostrii</p>
				<img src={Testimonial1} alt='testimonial' />
				<img src={Testimonial2} alt='testimonial' />
			</div>
			<div className='carousel'>
				<LoadingOverlay visible={fileLoadingOverlay} />
				<Swiper ref={swiperRef} loop={schoolImages.length > 1} autoHeight slidesPerView={1}>
					{schoolImages.map((file, index) => (
						<SwiperSlide className='carousel-slide' key={index}>
							<img onLoad={() => setFileLoadingOverlay(false)} src={file} alt='vehicul' />
						</SwiperSlide>
					))}
				</Swiper>
				{schoolImages?.length > 1 && (
					<>
						<div className='swiper-button previous-button' onClick={() => swiperRef.current.swiper.slidePrev()}>
							<IconChevronLeft className='button-icon' />
						</div>
						<div className='swiper-button next-button' onClick={() => swiperRef.current.swiper.slideNext()}>
							<IconChevronRight className='button-icon' />
						</div>
					</>
				)}
			</div>
		</div>
	);
};
export default HomePage;
