import './LogoHeader.scss';
import logo from '../../images/logo.png';

const LogoHeader = () => {
	return (
		<div className='logo-header'>
			<img src={logo} alt='logo' />
		</div>
	);
};
export default LogoHeader;
