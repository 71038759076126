import BIcon from "./images/categoryIcons/B.webp";
import CIcon from "./images/categoryIcons/C.webp";
import CEIcon from "./images/categoryIcons/CE.webp";
import CarImg1 from "./images/categoryImages/B/1.webp";
import CarImg10 from "./images/categoryImages/B/10.webp";
import CarImg2 from "./images/categoryImages/B/2.webp";
import CarImg3 from "./images/categoryImages/B/3.webp";
import CarImg4 from "./images/categoryImages/B/4.webp";
import CarImg5 from "./images/categoryImages/B/5.webp";
import CarImg6 from "./images/categoryImages/B/6.webp";
import CarImg7 from "./images/categoryImages/B/7.webp";
import CarImg8 from "./images/categoryImages/B/8.webp";
import CarImg9 from "./images/categoryImages/B/9.webp";
import TruckImg1 from "./images/categoryImages/C/1.webp";
import BigTruckImg1 from "./images/categoryImages/CE/1.webp";
import SchoolImage1 from "./images/school/1.webp";
import SchoolImage2 from "./images/school/2.webp";
import SchoolImageMain from "./images/school/main.webp";

export const schoolImages = [SchoolImageMain, SchoolImage1, SchoolImage2];

export const categories = [
	{
		title: "Categoria B",
		icon: BIcon,
		url: "/categoria-b",
		//* max 3 vehicles
		vehicles: ["Ford Focus", "Audi A3", "Dacia Duster"],
		images: [CarImg1, CarImg2, CarImg3, CarImg4, CarImg5, CarImg6, CarImg7, CarImg8, CarImg9, CarImg10],
		instructori: [
			{
				name: "Craciun Liviu",
				sex: "M",
				vehicle: "Ford Focus",
				vehicleNumber: "BH 26 YUL",
				vehicleImage: CarImg1,
			},
			{
				name: "Susman Corneliu",
				sex: "M",
				vehicle: "Audi A3",
				vehicleNumber: "BH 01 HEY",
				vehicleImage: CarImg2,
			},
			{
				name: "Zeres Ioan",
				sex: "M",
				vehicle: "Dacia Duster",
				vehicleNumber: "BH 10 ZIG",
				vehicleImage: CarImg4,
			},
			{
				name: "Mihuta Dorina",
				sex: "F",
				vehicle: "Renault Clio",
				vehicleNumber: "BH 16 MEY",
				vehicleImage: CarImg5,
			},
			{
				name: "Purda Adrian",
				sex: "M",
				vehicle: "Ford Focus",
				vehicleNumber: "BH 38 ROA",
				vehicleImage: CarImg3,
			},
			{
				name: "Natea Alexandru",
				sex: "M",
				vehicle: "Renault Megane",
				vehicleNumber: "BH 66 CZR",
				vehicleImage: CarImg6,
			},
			{
				name: "Bodea Cornel",
				sex: "M",
				vehicle: "Opel Corsa",
				vehicleNumber: "BH 80 XIA",
				vehicleImage: CarImg7,
			},
			{
				name: "Benea Nicolae",
				sex: "M",
				vehicle: "Hyundai I 30",
				vehicleNumber: "BH 88 NBD",
				vehicleImage: CarImg8,
			},
			{
				name: "Popa Mircea",
				sex: "M",
				vehicle: "Volkswagen Golf 6",
				vehicleNumber: "BH 03 AGU",
				vehicleImage: CarImg9,
			},
			{
				name: "Popa Mircea",
				sex: "M",
				vehicle: "Honda Jazz - Automata",
				vehicleNumber: "BH 77 AGU",
				vehicleImage: CarImg10,
			},
		],
	},
	{
		title: "Categoria C",
		icon: CIcon,
		url: "/categoria-c",
		vehicles: ["Camion MAN"],
		images: [TruckImg1],
		instructori: [
			{
				name: "Zeres Ioan",
				sex: "M",
				vehicle: "Camion MAN",
				vehicleNumber: "BH 30 ASD",
				vehicleImage: TruckImg1,
			},
		],
	},
	{
		title: "Categoria CE",
		icon: CEIcon,
		url: "/categoria-ce",
		vehicles: ["Camion MAN cu remorca de Rema"],
		images: [BigTruckImg1],
		instructori: [
			{
				name: "Zeres Ioan",
				sex: "M",
				vehicle: "Camion MAN cu remorca de Rema",
				vehicleNumber: "BH 30 ASD",
				vehicleImage: BigTruckImg1,
			},
		],
	},
];

export const contactInfo = {
	address: "Str. Partenie Cosma nr. 17, Oradea",
	email: "autostardriver@yahoo.com",
	phone1: "0771 630 952",
	phone1raw: "0771630952",
	phone2: "0766 211 559",
	phone2raw: "0766211559",
	orar: {
		luni: {
			start: "9:00",
			end: "17:00",
		},
		marti: {
			start: "9:00",
			end: "17:00",
		},
		miercuri: {
			start: "9:00",
			end: "17:00",
		},
		joi: {
			start: "9:00",
			end: "17:00",
		},
		vineri: {
			start: "9:00",
			end: "17:00",
		},
		sambata: {
			closed: true,
		},
		duminica: {
			closed: true,
		},
	},
	socialMedia: {
		facebook: "https://www.facebook.com/AutoStarDriver/",
		instagram: "https://www.instagram.com/auto.star_driver/",
	},
};
