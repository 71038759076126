import './ContactPage.scss';
import map from '../../images/map.webp';
import { useClipboard } from '@mantine/hooks';
import facebookIcon from '../../images/icons/facebook.webp';
import instagramIcon from '../../images/icons/instagram.webp';
import { contactInfo } from '../../SchoolData';

const ContactPage = () => {
	const clipboardAddress = useClipboard({ timeout: 200 });
	const clipboardTel1 = useClipboard({ timeout: 200 });
	const clipboardTel2 = useClipboard({ timeout: 200 });
	const clipboardMail = useClipboard({ timeout: 200 });

	return (
		<div className='page page-contact'>
			<div className='map-contact'>
				<a
					href='https://www.google.com/maps/place/Auto+Star+Driver/@47.0579504,21.9127519,14z/data=!3m1!4b1!4m5!3m4!1s0x47464979af92ea5f:0xfe2133e80e982459!8m2!3d47.0657699!4d21.9270155'
					target='_blank'
					rel='noreferrer'>
					<img src={map} alt='harta' />
				</a>
				<div className='contact-details'>
					<p>
						Adresa:
						<span
							className='address'
							style={{
								color: clipboardAddress.copied ? '#d64933' : '#e4e4e4',
								marginLeft: '25px',
								textAlign: 'center',
							}}
							onClick={() => clipboardAddress.copy(contactInfo.address)}>
							{contactInfo.address}
						</span>
					</p>
					<p>
						Email:
						<span
							className='email'
							style={{ color: clipboardMail.copied ? '#d64933' : '#e4e4e4', marginLeft: '40px' }}
							onClick={() => clipboardMail.copy(contactInfo.email)}>
							{contactInfo.email}
						</span>
					</p>
					<p>
						Telefon:
						<span
							className='tel'
							style={{ color: clipboardTel1.copied ? '#d64933' : '#e4e4e4', marginLeft: '23px' }}
							onClick={() => clipboardTel1.copy(contactInfo.phone1raw)}>
							{contactInfo.phone1}
						</span>
						<span
							style={{ color: clipboardTel2.copied ? '#d64933' : '#e4e4e4', marginLeft: '20px' }}
							onClick={() => clipboardTel2.copy(contactInfo.phone2raw)}>
							{contactInfo.phone2}
						</span>
					</p>
					<div className='orar'>
						Orar:
						<div className='days' style={{ marginLeft: '53px' }}>
							<p>Luni:</p>
							<p>Marti:</p>
							<p>Miercuri:</p>
							<p>Joi:</p>
							<p>Vineri:</p>
							<p>Sambata:</p>
							<p>Duminica:</p>
						</div>
						<div className='hours' style={{ textAlign: 'center', marginLeft: '50px' }}>
							<p>
								{contactInfo.orar.luni.closed
									? 'Inchis'
									: `${contactInfo.orar.luni.start} - ${contactInfo.orar.luni.end}`}
							</p>
							<p>
								{contactInfo.orar.marti.closed
									? 'Inchis'
									: `${contactInfo.orar.marti.start} - ${contactInfo.orar.marti.end}`}
							</p>
							<p>
								{contactInfo.orar.miercuri.closed
									? 'Inchis'
									: `${contactInfo.orar.miercuri.start} - ${contactInfo.orar.miercuri.end}`}
							</p>
							<p>
								{contactInfo.orar.joi.closed
									? 'Inchis'
									: `${contactInfo.orar.joi.start} - ${contactInfo.orar.joi.end}`}
							</p>
							<p>
								{contactInfo.orar.vineri.closed
									? 'Inchis'
									: `${contactInfo.orar.vineri.start} - ${contactInfo.orar.vineri.end}`}
							</p>
							<p>
								{contactInfo.orar.sambata.closed
									? 'Inchis'
									: `${contactInfo.orar.sambata.start} - ${contactInfo.orar.sambata.end}`}
							</p>
							<p>
								{contactInfo.orar.duminica.closed
									? 'Inchis'
									: `${contactInfo.orar.duminica.start} - ${contactInfo.orar.duminica.end}`}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className='social-media'>
				<p className='title'>Social Media</p>
				<div className='line' />
				<a href={contactInfo.socialMedia.facebook} target='_blank' rel='noreferrer' className='platforms'>
					<img src={facebookIcon} alt='facebook' />
					<p>Auto Star Driver</p>
				</a>
				<a href={contactInfo.socialMedia.instagram} target='_blank' rel='noreferrer' className='platforms insta'>
					<img src={instagramIcon} alt='instagram' />
					<p>@auto.star_driver</p>
				</a>
			</div>
		</div>
	);
};
export default ContactPage;
