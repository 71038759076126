import { LoadingOverlay } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import InstructorCard from '../../components/InstructorCard/InstructorCard';
import './CategoryPage.scss';
import DocumentsIcon from '../../images/icons/documents.webp';

const CategoryPage = ({ category }) => {
	const [fileLoadingOverlay, setFileLoadingOverlay] = useState(true);
	const swiperRef = useRef();

	useEffect(() => {
		const interval = setInterval(() => {
			swiperRef.current.swiper.slideNext();
		}, 5000);
		return () => clearInterval(interval);
	}, []);

	return (
		<div className='page page-category'>
			<div className='header'>
				<div className='icon-title'>
					<img src={category.icon} alt='prezentare categorie' />
					<p>{category.title}</p>
				</div>
				<div className='carousel'>
					<LoadingOverlay visible={fileLoadingOverlay} />
					<Swiper ref={swiperRef} loop={category.images?.length > 1} autoHeight slidesPerView={1}>
						{category.images.map((file, index) => (
							<SwiperSlide className='carousel-slide' key={index}>
								<img onLoad={() => setFileLoadingOverlay(false)} src={file} alt='vehicul' />
							</SwiperSlide>
						))}
					</Swiper>
					{category.images?.length > 1 && (
						<>
							<div className='swiper-button previous-button' onClick={() => swiperRef.current.swiper.slidePrev()}>
								<IconChevronLeft className='button-icon' />
							</div>
							<div className='swiper-button next-button' onClick={() => swiperRef.current.swiper.slideNext()}>
								<IconChevronRight className='button-icon' />
							</div>
						</>
					)}
				</div>
			</div>
			<div className='body'>
				<div className='instructori'>
					<p>Instructori</p>
					<div className='cards'>
						{category.instructori?.map((instructor) => (
							<InstructorCard
								name={instructor.name}
								vehicle={instructor.vehicle}
								vehicleNumber={instructor.vehicleNumber}
								vehicleImage={instructor.vehicleImage}
							/>
						))}
					</div>
				</div>
				<div id='documente' className='documente'>
					<div className='title'>Documente Necesare</div>
					<img src={DocumentsIcon} alt='documente' />
					<div className='list'>
						<p>• Buletin</p>
						<p>• Cazier</p>
						<p>• Aviz Medical</p>
						<p>• Aviz Psihologic</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CategoryPage;
