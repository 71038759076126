import './AboutPage.scss';
import { categories } from '../../SchoolData';
import Img1 from '../../images/school/main.webp';
import Img2 from '../../images/school/2.webp';
import Testimonial1 from '../../images/testimonials/1.webp';
import Testimonial2 from '../../images/testimonials/2.webp';
import Testimonial3 from '../../images/testimonials/3.webp';
import Testimonial4 from '../../images/testimonials/4.webp';
import Testimonial5 from '../../images/testimonials/5.webp';
import InstructorCarCard from '../../components/InstructorCarCard/InstructorCarCard';

const AboutPage = () => {
	return (
		<div className='page page-about'>
			<div className='presentation'>
				<div className='slide'>
					<img src={Img1} alt='prezentare' />
					<p>
						Desi recent infiintata, Scoala Auto Star Driver si instructorii acesteia au printre cele mai bune
						review-uri de la absolventi, dand dovada de seriozitate si profesionalism.
					</p>
				</div>
				<div className='slide'>
					<img src={Img2} alt='prezentare' />
					<p>
						Oferim o metoda profesionala unde orele petrecute cu noi sunt planuite cu grija, instructorii nostrii
						asigurandu-se ca elevii sa atinga o performanta maxima in cel mai scurt timp, atat in teorie cat si in
						practica.
					</p>
				</div>
			</div>
			<div className='personal'>
				<p className='header'>Instructori si Vehicule</p>
				<div className='body'>
					{categories.map((category) =>
						category.instructori.map((instructor) => (
							<InstructorCarCard
								name={instructor.name}
								image={instructor.vehicleImage}
								number={instructor.vehicleNumber}
							/>
						))
					)}
				</div>
			</div>
			<div className='testimonials'>
				<p>Ce spun clientii nostrii</p>
				<img src={Testimonial1} alt='testimonial' />
				<img src={Testimonial2} alt='testimonial' />
				<img src={Testimonial3} alt='testimonial' />
				<img src={Testimonial4} alt='testimonial' />
				<img src={Testimonial5} alt='testimonial' />
			</div>
		</div>
	);
};
export default AboutPage;
