import './NavBar.scss';
import { NavLink } from 'react-router-dom';

const NavBar = () => {
	return (
		<div className='navbar'>
			<NavLink to='/' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
				<p>Acasa</p>
			</NavLink>
			<NavLink to='/despre-noi' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
				<p>Despre Noi</p>
			</NavLink>
			<NavLink to='/oferta' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
				<p>Oferta</p>
			</NavLink>
			<NavLink to='/contact' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
				<p>Contact</p>
			</NavLink>
		</div>
	);
};
export default NavBar;
