import "./InstructorCarCard.scss";

const InstructorCarCard = ({ image, name, number }) => {
	return (
		<div className="instructorcar-card">
			<img src={image} alt="vehicul" />
			<p>Instructor: {name}</p>
			<p>Vehicul: {number}</p>
		</div>
	);
};
export default InstructorCarCard;
