import "./InstructorCard.scss";

const InstructorCard = ({ name, vehicle, vehicleNumber, vehicleImage }) => {
	return (
		<div className="instructor-card">
			<div className="header">
				<img src={vehicleImage} alt="masina" />
			</div>
			<div className="body">
				<p>{name}</p>
				<p>{vehicle}</p>
				<p>{vehicleNumber}</p>
			</div>
		</div>
	);
};
export default InstructorCard;
