import './Footer.scss';
import logo from '../../images/logo.png';
import { NavLink } from 'react-router-dom';
import { useClipboard } from '@mantine/hooks';

const Footer = () => {
	const clipboardAddress = useClipboard({ timeout: 200 });
	const clipboardTel1 = useClipboard({ timeout: 200 });
	const clipboardTel2 = useClipboard({ timeout: 200 });
	const clipboardMail = useClipboard({ timeout: 200 });

	return (
		<div className='footer'>
			<div className='footer-light'>
				<img src={logo} alt='' />
				{/* //! business name */}
				<p className='business'>© 2022 Scoala Auto Star Driver</p>
				<p>
					Icons by{' '}
					<a href='https://www.flaticon.com/authors/freepik' rel='noreferrer' target='_blank'>
						Freepik
					</a>{' '}
					&{' '}
					<a href='https://www.flaticon.com/authors/stockio' rel='noreferrer' target='_blank'>
						Stockio
					</a>
				</p>
				<svg width='312' height='100%' viewBox='0 0 500 400' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M500 0V400H0L500 0Z' fill='#27252C' />
				</svg>
			</div>
			<div className='footer-dark'>
				<div className='display'>
					<p className='title'>Informatii Utile</p>
					<div className='line' />
					<NavLink to='/despre-noi' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
						<p>Despre Noi</p>
					</NavLink>
					{/* //!navigate to documents */}
					<NavLink to='/categoria-b'>
						<p>Documente Necesare</p>
					</NavLink>
					<NavLink to='/oferta' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
						<p>Oferta</p>
					</NavLink>
				</div>
				<div className='display'>
					<NavLink
						to='/contact'
						className='title'
						onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
						<p>Contact</p>
					</NavLink>
					<div className='line' />

					<p
						className='copy'
						style={{ color: clipboardAddress.copied ? '#d64933' : '#e4e4e4' }}
						onClick={() => clipboardAddress.copy('Str. Partenie Cosma nr. 17, Oradea')}>
						Str. Partenie Cosma nr. 17, Oradea
					</p>
					<p
						className='copy'
						style={{ color: clipboardTel1.copied ? '#d64933' : '#e4e4e4' }}
						onClick={() => clipboardTel1.copy('0771630952')}>
						0771 630 952
					</p>
					<p
						className='copy'
						style={{ color: clipboardTel2.copied ? '#d64933' : '#e4e4e4' }}
						onClick={() => clipboardTel2.copy('0766211559')}>
						0766 211 559
					</p>
					<p
						className='copy'
						style={{ color: clipboardMail.copied ? '#d64933' : '#e4e4e4' }}
						onClick={() => clipboardMail.copy('autostardriver@yahoo.com')}>
						autostardriver@yahoo.com
					</p>
				</div>
			</div>
		</div>
	);
};
export default Footer;
