import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { categories } from './SchoolData';
import Footer from './components/Footer/Footer';
import LogoHeader from './components/LogoHeader/LogoHeader';
import NavBar from './components/NavBar/NavBar';
import HomePage from './pages/Home/HomePage';
import AboutPage from './pages/About/AboutPage';
import OfertaPage from './pages/Oferta/OfertaPage';
import ContactPage from './pages/Contact/ContactPage';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import Page404 from './pages/Page404/Page404';

const App = () => {
	return (
		<div className='App'>
			<Router>
				<LogoHeader />
				<NavBar />
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/despre-noi' element={<AboutPage />} />
					<Route path='/oferta' element={<OfertaPage />} />
					<Route path='/contact' element={<ContactPage />} />
					{categories.map((category) => (
						<Route path={category.url} element={<CategoryPage category={category} />} />
					))}
					<Route path='*' element={<Page404 />} />
				</Routes>
				<Footer />
			</Router>
		</div>
	);
};

export default App;
